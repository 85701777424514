import AgentCaptchaRepository from '../api/captcha';

class AgentCaptchaUseCase {
  constructor() {
    this.api = new AgentCaptchaRepository();
  }

  async generateCaptcha() {
    const { data, err } = await this.api.generateCaptcha();
    if (data) {
      return { data: data.data, err: null };
    }
    return { data: null, err };
  }

  async validateCaptcha(axis) {
    const { data, err } = await this.api.validateCaptcha(axis);
    if (data) {
      return { data: data.data, err: null };
    }
    return { data: null, err };
  }
}

export default new AgentCaptchaUseCase();
