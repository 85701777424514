import InternalAPI from '@/app/baseAxios';
import { getAgentCaptchaKey, setAgentCaptchaKey } from '@/misc/Cookies';

export default class AgentCaptcha extends InternalAPI {
  constructor() {
    super();
    /** @type {string} */
    this.endpoint = '/v2/agent';
  }

  async generateCaptcha() {
    try {
      const res = await this.$axios.get(this.endpoint, {
        headers: {
          'Captcha-Key': getAgentCaptchaKey() || '',
        },
      });
      if (res.status === 200) {
        setAgentCaptchaKey(res.headers['captcha-key']);
        if (res.data) {
          return { data: res.data, err: null };
        }
      }
      return { data: res.data, err: null };
    } catch (err) {
      return { data: null, err: this.setErrorMessage(err.response.data.desc || err.response.data.message) };
    }
  }

  async validateCaptcha(axis) {
    try {
      const res = await this.$axios.post(this.endpoint, { axis }, {
        headers: {
          'Captcha-Key': getAgentCaptchaKey(),
        },
      });
      if (res.status === 200) {
        setAgentCaptchaKey(res.headers['captcha-key'], true);
        if (res.data) {
          return { data: res.data, err: null };
        }
      }
      return { data: res.data, err: null };
    } catch (err) {
      return { data: null, err: this.setErrorMessage(err.response.data.desc || err.response.data.message) };
    }
  }
}
