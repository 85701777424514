<template>
  <div class="agent-input-group">
    <span
      :id="`${name}-label`"
      :class="{required: isRequired && label, optional: isOptional}"
    >{{ label }}</span>
    <Input
      :name="name"
      :placeholder="placeholder"
      :input-type="type"
      :prefix="prefix"
      :custom-class="'prefix ' + name"
      :value="value"
      tabindex="0"
      @input="onInput"
    >
      <template #icon>
        <img
          v-if="iconType === 'map'"
          class="agent-input-group__icon"
          src="/images/ics_o_maps.svg"
          alt="maps icon"
        >
      </template>
    </Input>
    <div class="agent-input-group__messages">
      <span
        v-show="!isError && note"
        :id="`${name}-note`"
        class="agent-input-group__messages__note"
        v-html="note"
      />
      <div
        v-show="isError"
        class="agent-input-group__messages__error"
      >
        <img
          src="/images/ics_f_warning_triangles_red.svg"
          alt="warning icon"
        >
        <p
          :id="`${name}-error-message`"
          v-html="errorMessage"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Input from '@/components/new-input/Input.vue';
export default {
  components: {Input},
  props: {
    placeholder: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    isRequired: {
      type: Boolean
    },
    label: {
      type: String
    },
    type: {
      type: String,
      default: "text"
    },
    prefix: {
      type: String,
      default: ""
    },
    note: {
      type: String,
    },
    isError: {
      type: Boolean,
    },
    errorMessage: {
      type: String
    },
    iconType: {
      type: String
    },
    isOptional: {
      type: Boolean
    },
    value: {
      required: true
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  mounted() {
    this.decideErrorField()
    this.dicideDisableField()
    this.setInputId()
  },
  updated() {
    this.decideErrorField()
    this.dicideDisableField()
  },
  methods: {
    onInput(val) {
      this.$emit('input', val)
    },
    setInputId() {
      document.querySelector(`.${this.name}`).querySelector('input').setAttribute('id', this.name)
    },
    dicideDisableField() {
      if (this.disabled) {
        document.querySelector(`.${this.name}`).querySelector('input').setAttribute('disabled', true)
      } else {
        document.querySelector(`.${this.name}`).querySelector('input').removeAttribute('disabled')
      }
    },
    decideErrorField() {
      if (this.isError) {
        document.querySelector(`.${this.name}`).querySelector('input').classList.add('error')
      } else {
        document.querySelector(`.${this.name}`).querySelector('input').classList.remove('error')
      }
    }
  },
}
</script>
<style lang="scss" scoped>
@import "@/assets/css/style.scss";

.agent-input-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 24px 0px;
  span {
    font-size: 14px;
    line-height: 21px;
    color: $color-base-text;
    font-weight: 600;

    &.required {
      &::after {
        content: "*";
        color: $color-base;
        margin-left: 4px;
      }
    }
    &.optional {
      &::after {
        content: "(Opsional)";
        color: $color-gray-shade;
        margin-left: 4px;
        font-weight: 400;
      }
    }
  }
  &__icon {
    position: absolute;
    right: 10px;
    bottom: 0;
    top: 0;
    margin: auto;
  }
  &__messages {
    &__error {
      display: flex;
      gap: 8px;
      align-items: center;
      font-size: 12px !important;
      line-height: 18px !important;
      font-weight: 400 !important;
      color: $color-red-6 !important;
    }
    &__note {
      font-size: 12px !important;
      line-height: 18px !important;
      font-weight: 400 !important;
      color: $color-gray-shade !important;
    }
  }
  & ::v-deep .input-wrapper {
    &.prefix {
      display: flex !important;
      align-items: center;
      text-align: left !important;
    }
    & .padding-input-prefix {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      padding-left: 8px !important;
    }
    & .prefix {
      position: relative;
      padding: 7.5px 12px;
    }
  }
  & ::v-deep .input-breakpoint-style {
    border-color: $color-gray-pensive-5;

    &[name="latlong"] {
      padding-right: 33px !important;
    }

    &::placeholder {
      font-size: 14px;
      line-height: 21px;
      color: $color-gray-shade;
    }
    &.error {
      border:1px solid $color-base !important;
    }
    &:focus {
      transition: .1s ease-in-out !important;
      outline-color: $color-base !important;
      border:2px solid $color-base !important;
      caret-color: $color-base !important;
    }
  }
}
</style>
