<template>
  <div class="agent-dropdown-group">
    <span
      :id="`${name}-label`"
      :class="{required: isRequired, optional: isOptional}"
    >{{ label }}</span>
    <Dropdown
      :name="name"
      :placeholder="placeholder"
      :input-type="type"
      :prefix="prefix"
      :options="options"
      :is-error="isError"
      :value="value"
      :disabled="disabled"
      :is-search="isSearch"
      tabindex="0"
      @input="onInput"
    />
    <div class="agent-dropdown-group__messages">
      <span
        v-show="!isError && note"
        :id="`${name}-note`"
        class="agent-dropdown-group__messages__note"
        v-html="note"
      />
      <div
        v-show="isError"
        class="agent-dropdown-group__messages__error"
      >
        <img
          src="/images/ics_f_warning_triangles_red.svg"
          alt="warning icon"
        >
        <p
          :id="`${name}-error-message`"
          v-html="errorMessage"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Dropdown from '@/components/new-dropdown/Dropdown.vue';

export default {
  components: { Dropdown },
  props: {
    placeholder: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    isRequired: {
      type: Boolean,
    },
    label: {
      type: String,
    },
    type: {
      type: String,
      default: 'text',
    },
    prefix: {
      type: String,
      default: '',
    },
    note: {
      type: String,
    },
    isError: {
      type: Boolean,
    },
    errorMessage: {
      type: String,
    },
    isOptional: {
      type: Boolean,
    },
    options: {
      type: Array,
    },
    value: {
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    isSearch: {
      type: Boolean,
      default: false,
    },
  },
  updated() {
    this.decideDisableField();
  },
  mounted() {
    this.decideDisableField();
  },
  methods: {
    onInput(val) {
      this.$emit('input', val);
    },
    decideDisableField() {
      if (this.disabled) {
        const dropdownEl = document.querySelector(`[name="${this.name}"]`);
        if (dropdownEl.querySelector('.chevron')) {
          dropdownEl.querySelector('.chevron').style.pointerEvents = 'none';
        }
        if (dropdownEl.querySelector('.single-select')) {
          dropdownEl.querySelector('.single-select').style.pointerEvents = 'none';
        }
        if (dropdownEl.querySelector('.single-select')) {
          dropdownEl.querySelector('.single-select').style.background = 'whitesmoke';
        }
      } else {
        const dropdownEl = document.querySelector(`[name="${this.name}"]`);
        if (dropdownEl.querySelector('.chevron')) {
          dropdownEl.querySelector('.chevron').style.pointerEvents = 'fill';
        }
        if (dropdownEl.querySelector('.single-select')) {
          dropdownEl.querySelector('.single-select').style.pointerEvents = 'fill';
        }
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/css/style.scss";

.agent-dropdown-group {
   display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 24px 0px;
  span {
    font-size: 14px;
    line-height: 21px;
    color: $color-base-text;
    font-weight: 600;

    &.required {
      &::after {
        content: "*";
        color: $color-base;
        margin-left: 4px;
      }
    }
    &.optional {
      &::after {
        content: "(Opsional)";
        color: $color-gray-shade;
        margin-left: 4px;
        font-weight: 400;
      }
    }
  }
  &__messages {
    &__error {
      display: flex;
      gap: 8px;
      align-items: center;
      font-size: 12px !important;
      line-height: 18px !important;
      font-weight: 400 !important;
      color: $color-red-6 !important;
    }
    &__note {
      font-size: 12px !important;
      line-height: 18px !important;
      font-weight: 400 !important;
      color: $color-gray-shade !important;
    }
  }
  & ::v-deep .wrapper-multiselect-options {
    input {
      margin-top: unset!important;
      outline: none !important;
      border: none !important;
      border-color: $color-gray-pensive-5;
      color: $color-base-text;
      font-size: 14px;
      line-height: 21px;
      &::placeholder {
        font-size: 14px !important;
        line-height: 21px !important;
        color: $color-base-text !important;
      }
      &:focus {
        outline-color: $color-base !important;
        border:1px solid $color-base !important;
        caret-color: $color-base !important;
      }
    }
  }
}
</style>
