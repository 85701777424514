import Cookies from "js-cookie";
import {removeAgentCaptchaKey} from "@/misc/Cookies"

export const getPuzzleShapes = (imgUrl) => {
  const puzzle = {};

  const randomKey = getRandomInt(1, 1);

  if (randomKey === 1) {
    puzzle.fill = `<svg width="56" height="49" viewBox="0 0 56 49" fill="none" xmlns="http://www.w3.org/2000/svg" data-v-af8e19c4=""><g filter="url(#filter0_di_11924_7633)"><path d="M9.55993 2V10.7858C9.55993 11.5319 9.14347 12.2152 8.51259 12.6136C6.92787 13.6141 4.09396 15.716 4.00231 18C3.9045 20.4374 6.94097 22.7966 8.56587 23.8807C9.16881 24.283 9.55993 24.9491 9.55993 25.674V39C9.55993 40.1046 10.4554 41 11.5599 41H50C51.1046 41 52 40.1046 52 39V2C52 0.89543 51.1046 0 50 0H38.0104C37.289 0 36.6252 0.390021 36.2134 0.982338C35.3272 2.257 33.6149 4.33831 31.7905 4.5C29.6513 4.68958 27.4241 2.23428 26.3631 0.872924C25.9482 0.340648 25.3209 0 24.6461 0H11.5599C10.4554 0 9.55993 0.895431 9.55993 2Z" fill="url(#img1)" shape-rendering="crispEdges"/></g><defs><filter id="filter0_di_11924_7633" x="0" y="0" width="56" height="49" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dy="4"/><feGaussianBlur stdDeviation="2"/><feComposite in2="hardAlpha" operator="out"/><feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_11924_7633"/><feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_11924_7633" result="shape"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset/><feGaussianBlur stdDeviation="4"/><feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/><feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 0.635294 0 0 0 0 0 0 0 0 1 0"/><feBlend mode="normal" in2="shape" result="effect2_innerShadow_11924_7633"/></filter><pattern id="img1" patternUnits="userSpaceOnUse" width="100" height="100"><image href="${imgUrl}" x="0" y="0" width="48" height="41"/></pattern></defs></svg>`;
    puzzle.empty = `<svg width="56" height="49" viewBox="0 0 56 49" fill="none" xmlns="http://www.w3.org/2000/svg"><g filter="url(#filter0_di_11978_208378)"><path d="M9.55993 2V10.7858C9.55993 11.5319 9.14347 12.2152 8.51259 12.6136C6.92787 13.6141 4.09396 15.716 4.00231 18C3.9045 20.4374 6.94097 22.7966 8.56587 23.8807C9.16881 24.283 9.55993 24.9491 9.55993 25.674V39C9.55993 40.1046 10.4554 41 11.5599 41H50C51.1046 41 52 40.1046 52 39V2C52 0.89543 51.1046 0 50 0H38.0104C37.289 0 36.6252 0.390021 36.2134 0.982338C35.3272 2.257 33.6149 4.33831 31.7905 4.5C29.6513 4.68958 27.4241 2.23428 26.3631 0.872924C25.9482 0.340648 25.3209 0 24.6461 0H11.5599C10.4554 0 9.55993 0.895431 9.55993 2Z" fill="#5A5A5A" fill-opacity="0.96" shape-rendering="crispEdges"/></g><defs><filter id="filter0_di_11978_208378" x="0" y="0" width="56" height="49" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset dy="4"/><feGaussianBlur stdDeviation="2"/><feComposite in2="hardAlpha" operator="out"/><feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_11978_208378"/><feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_11978_208378" result="shape"/><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/><feOffset/><feGaussianBlur stdDeviation="14"/><feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/><feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0"/><feBlend mode="normal" in2="shape" result="effect2_innerShadow_11978_208378"/></filter></defs></svg>`;
  }
  return puzzle;
};

export const getRandomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const resizeImage = (coor, img, callback, errorcallback) => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  const imageObj = new Image();
  imageObj.crossOrigin = "Anonymous";

  // set canvas dimensions

  canvas.width = 48;
  canvas.height = 41;

  context.imageSmoothingEnabled = false;

  imageObj.onload = function() {
    context.drawImage(imageObj, coor.x, coor.y, 48, 41, 0, 0, 48, 41);
    try {
      callback(canvas.toDataURL());
    } catch (error) {
      errorcallback(error);
    }
  };
  imageObj.onerror = function() {
    errorcallback("Gagal memuat gambar. Coba kembali");
  };
  imageObj.src = img;
};

export const drawPuzzleShadow = (context) => {
  context.shadowOffsetX = 0;
  context.shadowOffsetY = 4;
  context.shadowBlur = 4;
  context.shadowColor = "rgba(0, 0, 0, 0.25)";
  context.beginPath();
};

export const drawPuzzleImage = (context, image, x, y, isPuzzle = true) => {
  context.clearRect(0, 0, 260, 162);
  context.imageSmoothingEnabled = false;
  if (isPuzzle) {
    context.drawImage(image, 0, 0, 48, 41, x, y, 48, 41);
  } else {
    context.drawImage(image, 0, 0, 260, 162);
  }
};

const getCaptchaCookieKey = (captcha_name) => {
  return `${process.env.NODE_ENV}_${captcha_name}_expires`;
};

const getCaptchaMaxAttemptExpiresKey = (captcha_name) => {
  return `${process.env.NODE_ENV}_${captcha_name}_attempt_expires`;
};

export const setCaptchaAttemptExpires = (captcha_name) => {
  const oneMinute = new Date(new Date().getTime() + 60 * 1000);
  Cookies.set(getCaptchaMaxAttemptExpiresKey(captcha_name), oneMinute, {
    expires: oneMinute,
  });
};

export const setCaptchaExipres = (captcha_name) => {
  const oneMinute = new Date(new Date().getTime() + 60 * 1000);

  Cookies.set(getCaptchaCookieKey(captcha_name), oneMinute, {
    expires: oneMinute
  });
};

export const getCaptchaExpires = (captcha_name) => {
  return Cookies.get(getCaptchaCookieKey(captcha_name))
}

export const getCaptchaAttemptExpires = (captcha_name) => {
  return Cookies.get(getCaptchaMaxAttemptExpiresKey(captcha_name));
};

export const resetCaptchaExpires = (captcha_name) => {
  Cookies.remove(getCaptchaCookieKey(captcha_name));
};

export const resetCaptcha = () => {
  removeAgentCaptchaKey()
}
