<template>
  <section>
    <section>
      <div class="captcha-checkbox-wrapper">
        <div
          v-show="!isCaptchaResolved"
          class="captcha-checkbox-wrapper__box"
        >
          <div
            ref="checkboxEl"
            class="captcha-checkbox-wrapper__box__check"
            :class="isCaptchaExpired && 'error'"
            @click="handleOpenCaptcha"
          />
          <div
            ref="spinnerEl"
            class="captcha-checkbox-wrapper__box__spinner"
          >
            <div class="captcha-checkbox-wrapper__box__spinner__overlay" />
          </div>
        </div>
        <div
          v-show="isCaptchaResolved"
          class="captcha-checkbox-wrapper__checked"
        />
        <div class="captcha-checkbox-wrapper__text">
          Verifikasi keamanan
        </div>
      </div>
      <div class="captcha-error-message">
        <span v-if="isError && !isCaptchaExpired">Centang untuk memverifikasi keamanan</span>
        <span v-if="isCaptchaExpired">Verifikasi expired. Centang kembali</span>
      </div>
    </section>
    <Modal
      v-if="isCaptchaOpen"
      :use-icon-close="false"
    >
      <section
        draggable="false"
        @mousemove="handleMove"
        @touchmove="handleMove"
        @mouseup="handleEnd"
        @touchend="handleEnd"
        @mouseleave="handleEnd"
      >
        <div class="captcha-puzzle-header">
          <span>Verifikasi Keamanan</span>
          <img
            src="/images/ics_o_close.svg"
            alt="close captcha"
            @click="closeCaptcha"
          >
        </div>
        <section class="captcha-puzzle-wrapper">
          <div
            class="captcha-puzzle-wrapper__canvas"
            :class="decideMaxAttemptError() && 'disabled'"
          >
            <canvas
              ref="captchaBgEl"
              class="captcha-puzzle-wrapper__canvas__bg"
              width="260"
              height="162"
            />
            <canvas
              v-show="!isLoadingCaptcha"
              ref="captchaEmptyEl"
              class="captcha-puzzle-wrapper__canvas__slice"
              width="260"
              height="162"
            />
            <canvas
              v-show="!isLoadingCaptcha"
              ref="captchaFillEl"
              class="captcha-puzzle-wrapper__canvas__slice"
              width="260"
              height="162"
            />
          </div>
          <img
            v-if="isCaptchaBroken"
            class="captcha-broken"
            src="/images/captcha-images/captcha_blank.svg"
            alt="captcha broken"
          >
          <div
            v-if="isLoadingCaptcha"
            class="captcha-loading-wrapper"
          >
            <div class="captcha-loading-wrapper__spinner" />
          </div>
          <div class="captcha-puzzle-wrapper__validation-text">
            <transition name="fadeHeight">
              <span
                v-if="isPuzzleResolved"
                class="captcha-puzzle-wrapper__validation-text__success"
              >Verifikasi berhasil</span>
              <span
                v-if="isCaptchaError"
                class="captcha-puzzle-wrapper__validation-text__error"
              >{{ captchaErrorMessage }}</span>
            </transition>
          </div>
          <div
            class="captcha-puzzle-wrapper__slider-wrapper"
            :class="decideDisableCaptcha && 'disabled'"
          >
            <div
              ref="sliderEl"
              class="captcha-puzzle-wrapper__slider-wrapper__slider"
              :class="decideDisableCaptcha && 'disabled'"
              draggable="false"
              @mousedown="handleStart"
              @mouseenter="handleEnter"
              @mouseleave="handleLeave"
              @touchstart="handleStart"
            >
              <img
                draggable="false"
                src="/images/ics_o_arrow_right_alt.svg"
                alt="slider arrow"
              >
            </div>
          </div>
        </section>
        <div class="captcha-puzzle-footer">
          <img
            :class="decideMaxAttemptError() && 'disabled'"
            src="/images/ics_o_sync_grey.svg"
            alt="refresh icon"
            @click="changeBackground"
          >
        </div>
      </section>
    </Modal>
  </section>
</template>
<script>
import Modal from '@/components/new-modal/Modal.vue';
import {
  getPuzzleShapes,
  getRandomInt,
  resizeImage,
  drawPuzzleImage,
  drawPuzzleShadow,
  setCaptchaExipres,
  setCaptchaAttemptExpires,
  getCaptchaAttemptExpires,
  getCaptchaExpires,
  resetCaptchaExpires,
  resetCaptcha,
} from './captcha';

export default {
  components: { Modal },
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    isError: {
      type: Boolean,
      default: false,
    },
    captchaName: {
      type: String,
      default: 'captcha_slider',
    },
    value: {
      type: Boolean,
      required: true,
    },
    generateCaptchaFunc: {
      type: Function,
      required: true,
    },
    validateCaptchaFunc: {
      type: Function,
      required: true,
    },
    isCaptchaExpired: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      captchaBgContext: '',
      captchaFillContext: '',
      captchaEmptyContext: '',
      puzzleImg: '',
      puzzleBase64: '',
      emptyPuzzleBase64: '',
      backgroundCaptcha: '',
      origin: {
        x: 0,
        y: 0,
      },
      trail: {
        x: [0],
        y: [0],
      },
      coor: {
        x: 0,
        y: 0,
      },
      solving: false,
      puzzleImage: !this.isSsr() && new Image(),
      emptyPuzzleImage: !this.isSsr() && new Image(),
      backgroundCaptchaImage: !this.isSsr() && new Image(),
      isCaptchaResolved: false,
      isCaptchaOpen: false,
      isPuzzleResolved: false,
      isPuzzleNotResolved: false,
      isMaxAttempted: false,
      isLoadingCaptcha: false,
      isCaptchaError: false,
      captchaErrorMessage: '',
      captchaExpiresTimout: null,
      isCaptchaBroken: false,
      resolvedAxis: 0,
    };
  },
  computed: {
    decideDisableCaptcha() {
      return (
        this.decideMaxAttemptError()
        || this.isCaptchaError
        || this.isLoadingCaptcha
      );
    },
  },
  mounted() {
    this.checkMaxAttemptedTimeOut();
    this.resetCaptchaExpires();
    if (!getCaptchaAttemptExpires(this.captchaName)) {
      resetCaptcha();
    }
    this.resolvedAxis = 0;
  },
  updated() {
    if (this.isCaptchaExpired) {
      this.$emit('input', false);
      this.isCaptchaResolved = false;
      resetCaptcha();
      this.resolvedAxis = 0;
    }
  },
  methods: {
    initCaptcha() {
      const captchaEmptyCanvas = this.$refs.captchaEmptyEl;
      const captchaFillCanvas = this.$refs.captchaFillEl;

      this.captchaEmptyContext = captchaEmptyCanvas.getContext('2d');
      this.captchaFillContext = captchaFillCanvas.getContext('2d');

      this.emptyPuzzleImage.src = this.emptyPuzzleBase64;
      this.puzzleImage.src = this.puzzleBase64;

      this.drawBackgroundCaptcha();

      this.emptyPuzzleImage.onload = () => {
        drawPuzzleImage(
          this.captchaEmptyContext,
          this.emptyPuzzleImage,
          this.coor.x,
          this.coor.y,
        );
      };

      this.emptyPuzzleImage.onerror = () => {
        this.setBrokenImage();
      };

      this.puzzleImage.onload = () => {
        drawPuzzleImage(
          this.captchaFillContext,
          this.puzzleImage,
          0,
          this.coor.y,
        );
      };

      this.puzzleImage.onerror = () => {
        this.setBrokenImage();
      };

      drawPuzzleShadow(this.captchaFillContext);
      drawPuzzleShadow(this.captchaEmptyContext);
    },
    drawBackgroundCaptcha() {
      const captchaBgCanvas = this.$refs.captchaBgEl;
      this.captchaBgContext = captchaBgCanvas.getContext('2d');
      this.captchaBgContext.imageSmoothingEnabled = false;
      this.backgroundCaptchaImage.src = this.backgroundCaptcha;

      this.backgroundCaptchaImage.onload = () => {
        drawPuzzleImage(
          this.captchaBgContext,
          this.backgroundCaptchaImage,
          0,
          0,
          false,
        );
        this.isLoadingCaptcha = false;
        this.isCaptchaBroken = false;
      };

      this.backgroundCaptchaImage.onerror = () => {
        this.setBrokenImage();
      };
    },
    redrawCaptcha() {
      drawPuzzleImage(
        this.captchaFillContext,
        this.puzzleImage,
        this.scaleSliderPosition(),
        this.coor.y,
      );
      drawPuzzleShadow(this.captchaFillContext);
      this.captchaFillContext.beginPath();
    },
    createPuzzleSvg() {
      if (this.isMaxAttempted) return Promise.reject();
      return new Promise((resolve) => {
        resizeImage(
          this.coor,
          this.backgroundCaptcha,
          (url) => {
            this.puzzleImg = url;
            const puzzle = getPuzzleShapes(url);
            if (!this.isSsr()) {
              this.puzzleBase64 = `data:image/svg+xml;base64,${window.btoa(puzzle.fill)}`;
              this.emptyPuzzleBase64 = `data:image/svg+xml;base64,${window.btoa(puzzle.empty)}`;
              resolve(this.puzzleBase64);
            }
          },
          (err) => {
            this.$refs.checkboxEl.classList.remove('spin');
            this.$refs.spinnerEl.classList.remove('spin');
            this._setErrorCaptcha(err);
            this.setBrokenImage();
          },
        );
      });
    },
    async changeBackground(e) {
      e.preventDefault();
      if (this.decideDisableCaptcha) return;
      await this.generateCaptcha();

      await this.handleEnd();
    },
    handleStart(e) {
      if (this.decideDisableCaptcha) return;
      this.origin.x = e.clientX || e.touches[0].clientX;
      this.origin.y = e.clientY || e.touches[0].clientY;
      this.solving = true;
      this.$refs.sliderEl.style.transition = 'none';
    },
    handleMove(e) {
      if (!this.solving) return;
      const move = {
        x: (e.clientX || e.touches[0].clientX) - this.origin.x,
        y: (e.clientY || e.touches[0].clientY) - this.origin.y,
      };
      if (move.x > 225 || move.x < 0) return; // Don't update if outside bounds of captcha
      this.trail.x = this.trail.x.concat([move.x]);
      this.trail.y = this.trail.y.concat([move.y]);
      this.$refs.sliderEl.style.transform = `translate(${this.scaleSliderPosition()}px, -1px)`;
      this.redrawCaptcha();
      if (this.scaleSliderPosition() > 224) {
        this._onCaptchaFailed();
      }
    },
    async handleEnd() {
      this.$refs.sliderEl.style.transition = 'all 0.3s ease-in-out';
      if (!this.solving) return;
      if (this.isMaxAttempted) {
        this.$emit('max-attempted');
        await this.checkMaxAttemptedTimeOut();
      }
      this.solving = false;
      this.isLoadingCaptcha = true;
      const { data, err } = await this.validateCaptchaFunc(
        this.scaleSliderPosition(),
      );
      this.isLoadingCaptcha = false;
      if (data) {
        this._onCaptchaResolved();
      }
      if (err) {
        this.isPuzzleNotResolved = true;
        this._setErrorCaptcha(err);
        this._checkMaxAttemptedError(err);
        setTimeout(() => {
          this._onCaptchaFailed();
          if (!this.isMaxAttempted) {
            this._removeErrorCaptcha();
          }
        }, 1000);
      }
    },
    handleEnter(e) {
      if (this.solving) {
        e.preventDefault();
      }
    },
    handleLeave(e) {
      if (this.solving) {
        e.preventDefault();
      }
    },
    scaleSliderPosition() {
      return Math.floor(this.trail.x[this.trail.x.length - 1]);
    },
    async generateCaptcha() {
      this.puzzleImg = '';
      this.origin.x = 0;
      if (!this.isMaxAttempted) {
        this._removeErrorCaptcha();
        this.isLoadingCaptcha = true;
        try {
          const { data, err } = await this.generateCaptchaFunc();
          this.isCaptchaOpen = true;
          if (data) {
            this.resetCaptchaExpires();
            this.backgroundCaptcha = data.captcha;
            this.coor.y = getRandomInt(20, 110);
            this.coor.x = data.axis;
            this.createPuzzleSvg().then(() => {
              this.initCaptcha();
            });
            this.setCaptchaExpires();
          }
          if (err) {
            this.isCaptchaOpen = true;
            this._setErrorCaptcha(err);
            this._checkMaxAttemptedError(err);
            this.resetCaptchaExpires();
            this.createPuzzleSvg().then(() => {
              this.initCaptcha();
            }).catch(() => {
              this.setBrokenImage();
            });
          }
        } catch (err) {
          this.isCaptchaOpen = true;
          this._setErrorCaptcha(err);
          this.setBrokenImage();
        }
      } else {
        this.isCaptchaOpen = true;
        this.createPuzzleSvg().then(() => {
          this.initCaptcha();
        }).catch(() => {
          this.setBrokenImage();
        });
      }
    },
    setBrokenImage() {
      if (!this.backgroundCaptcha) {
        this.isCaptchaBroken = true;
      } else {
        this.drawBackgroundCaptcha();
      }
    },
    setCaptchaExpires() {
      if (!getCaptchaExpires(this.captchaName)) { setCaptchaExipres(this.captchaName); }
      clearTimeout(this.captchaExpiresTimout);
      this.captchaExpiresTimout = setTimeout(() => {
        this._decideCaptchaExpiredError();
      }, new Date(getCaptchaExpires(this.captchaName)) - new Date());
    },
    resetCaptchaExpires() {
      resetCaptchaExpires(this.captchaName);
    },
    _decideCaptchaExpiredError() {
      if (this.isCaptchaOpen) {
        this.solving = false;
        this._resetTrail();
        this._setErrorCaptcha('Captcha telah kadaluarsa, coba kembali 1 menit');
        setTimeout(() => {
          this.generateCaptcha();
        }, 3000);
      }
    },
    _checkMaxAttemptedError(err) {
      if (err.match('5') && !this.isMaxAttempted) {
        this.resetCaptchaExpires();
        this.isMaxAttempted = true;
        this.isLoadingCaptcha = false;
        if (!getCaptchaAttemptExpires(this.captchaName)) {
          clearTimeout(this.captchaExpiresTimout);
          setCaptchaAttemptExpires(this.captchaName);
          this.checkMaxAttemptedTimeOut();
        }
      }
    },
    async handleOpenCaptcha() {
      this.$emit('open');
      this.isPuzzleResolved = false;
      this.$refs.checkboxEl.classList.add('spin');
      this.$refs.spinnerEl.classList.add('spin');
      await this.generateCaptcha();
      this.$refs.checkboxEl.classList.remove('spin');
      this.$refs.spinnerEl.classList.remove('spin');
    },
    _setErrorCaptcha(msg) {
      this.isCaptchaError = true;
      this.captchaErrorMessage = msg;
    },
    _removeErrorCaptcha() {
      this.isCaptchaError = false;
      this.captchaErrorMessage = '';
    },
    closeCaptcha() {
      this.isCaptchaOpen = false;
      this.resetCaptchaExpires();
    },
    async checkMaxAttemptedTimeOut() {
      if (!getCaptchaAttemptExpires(this.captchaName)) return;
      setTimeout(async () => {
        this.isMaxAttempted = false;
        this._removeErrorCaptcha();
        if (this.isCaptchaOpen) {
          await this.generateCaptcha();
        }
      }, new Date(getCaptchaAttemptExpires(this.captchaName)) - new Date());
    },
    decideMaxAttemptError() {
      return (
        !this.isPuzzleNotResolved
        && !this.isPuzzleResolved
        && this.isMaxAttempted
      );
    },
    _onCaptchaResolved() {
      this.isPuzzleResolved = true;
      this.resolvedAxis = this.scaleSliderPosition();
      setTimeout(() => {
        this._resetTrail();
        this.closeCaptcha();
        this.isCaptchaResolved = true;
        this.$emit('verified', this.resolvedAxis);
        this.$emit('input', true);
      }, 1000);
    },
    _onCaptchaFailed() {
      this.isPuzzleNotResolved = false;
      this._resetTrail();
      this.redrawCaptcha();
      this.$emit('failed');
      this.$emit('input', false);
    },
    _resetTrail() {
      this.$refs.sliderEl.style.transform = 'translate(0px, -1px)';
      this.trail = {
        x: [0],
        y: [0],
      };
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./style.scss";
</style>
